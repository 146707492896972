/*
 * DeliveryDateTime Messages
 *
 * This contains all the text for the DeliveryDateTime container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DeliveryDateTime';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Thời gian giao hàng!',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Lưu ý:',
  },
  specialDateNote: {
    id: `${scope}.specialDateNote`,
    defaultMessage:
      'Bạn đang chọn giao hàng vào ngày lễ. Hãy cân nhắc chọn thời gian giao hàng sớm hơn để chuẩn bị tốt hơn.',
  },
  specialDateSubNote: {
    id: `${scope}.specialDateSubNote`,
    defaultMessage: 'Thời gian giao hàng sẽ trở lại bình thường trong đơn tiếp theo.',
  },
  holidayNote: {
    id: `${scope}.holidayNote`,
    defaultMessage: 'Ngày lễ',
  },
});
