import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const fetchFrequentlyBuyTogetherProduct = (data: any) =>
  action(ActionTypes.FETCH_FREQUENTLY_BUY_TOGETHER_PRODUCT, data);
export const setFrequentlyBuyTogetherProduct = (data: any) =>
  action(ActionTypes.SET_FREQUENTLY_BUY_TOGETHER_PRODUCT, data);
export const productNote = createAsyncAction(
  ActionTypes.SET_PRODUCT_NOTE_REQUEST,
  ActionTypes.SET_PRODUCT_NOTE_SUCCESS,
  ActionTypes.SET_PRODUCT_NOTE_FAILURE,
)<void, {}, Error>();
export const setFavorite = (data: any) => action(ActionTypes.SET_FAVORITE, data);
export const favorite = createAsyncAction(
  ActionTypes.FAVORITE_REQUEST,
  ActionTypes.FAVORITE_SUCCESS,
  ActionTypes.FAVORITE_FAILURE,
)<void, {}, Error>();
export const setProductNote = (data: any) => action(ActionTypes.SET_PRODUCT_NOTE, data);
export const updateNoteToCard = (data: any) => action(ActionTypes.UPDATE_NOTE_TO_CART, data);
