enum ActionTypes {
  FETCH_FREQUENTLY_BUY_TOGETHER_PRODUCT = 'app/FrequentlyBuyTogether/FETCH_FREQUENTLY_BUY_TOGETHER_PRODUCT',
  SET_FREQUENTLY_BUY_TOGETHER_PRODUCT = 'app/FrequentlyBuyTogether/SET_FREQUENTLY_BUY_TOGETHER_PRODUCT',
  SET_PRODUCT_NOTE = 'app/FrequentlyBuyTogether/SET_PRODUCT_NOTE',
  SET_PRODUCT_NOTE_REQUEST = 'app/FrequentlyBuyTogether/SET_PRODUCT_NOTE_REQUEST',
  SET_PRODUCT_NOTE_SUCCESS = 'app/FrequentlyBuyTogether/SET_PRODUCT_NOTE_SUCCESS',
  SET_PRODUCT_NOTE_FAILURE = 'app/FrequentlyBuyTogether/SET_PRODUCT_NOTE_FAILURE',
  UPDATE_NOTE_TO_CART = 'app/FrequentlyBuyTogether/UPDATE_NOTE_TO_CART',
  SET_FAVORITE = 'app/FrequentlyBuyTogether/SET_FAVORITE',
  FAVORITE_SUCCESS = 'app/FrequentlyBuyTogether/FAVORITE_SUCCESS',
  FAVORITE_REQUEST = 'app/FrequentlyBuyTogether/FAVORITE_REQUEST',
  FAVORITE_FAILURE = 'app/FrequentlyBuyTogether/FAVORITE_FAILURE',
}

export default ActionTypes;
